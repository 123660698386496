@use "colors" as *;

code[class*="language-"],
pre[class*="language-"] {
    color: $prism-white;
}

.token.argument {
    color: $prism-white;
    font-style: italic;
}

.token.comment {
    color: $prism-green;
    font-style: italic;
}

.token.super,
.token.type {
    color: $prism-cyan;
    font-style: italic;
}

.token.variable {
    color: $prism-cyan;
}

.token.modifier {
    color: $prism-blue;
    font-style: italic;
}

.token.class-name,
.token.number,
.token.datetime,
.token.constant {
    color: $prism-blue;
}

.token.function {
    color: $prism-light-green;
}

.token.string {
    color: $prism-yellow;
}

.token.operator,
.token.keyword,
.token.punctuation,
.token.special {
    color: $prism-pink;
}

.token.constraint {
    color: $prism-purple;
}

.token.object_label {
    color: $prism-grey;
}

.token.irrelevant {
    color: $prism-very-grey;
}

.token.annotation {
    color: $prism-orange;
}

.token.command {
    color: $prism-yellow-orange;
}

.token.intention {
    color: $prism-orange;
    font-style: italic;
}

.token.console {
    color: $prism-yellow-orange;
    font-style: italic;
}

.token.error {
    color: $prism-red;
}
