@use "colors";
@use "media";
@use "mixins" as *;
@use "shapes";

html,
body {
    color-scheme: dark;
    background-color: var(--body-background);
    color: var(--body-font-color);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-thin);
    line-height: var(--line-height-p2);
    letter-spacing: var(--body-letter-spacing);

    font-family: var(--default-font-family);

    margin: 0;
    scroll-padding-top: var(--scroll-padding);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

code,
kbd,
pre {
    font-family: var(--code-font-family);
}

@each $tag in (h1, h2, h3, h4, h5, h6) {
    #{$tag} {
        font-size: var(--font-size-#{$tag});
        line-height: var(--line-height-#{$tag});
    }

    #{$tag} code {
        font-size: 75%;
    }
}

.text-p1,
.text-p1 p {
    font-size: var(--font-size-p1);
    line-height: var(--line-height-p1);
}

p,
.text-p2 {
    font-size: var(--font-size-p2);
    line-height: var(--line-height-p2);
}

.text-blog,
.text-blog p {
    font-size: var(--font-size-blog);
    line-height: var(--line-height-blog);
}

.text-blog {
    p code, li code, table code {
        font-size: 75%;
    }
}

aside, .text-aside {
    font-size: var(--font-size-aside);
    line-height: var(--line-height-aside);
}

pre, code, .text-code {
    font-size: var(--font-size-code);
    line-height: var(--line-height-code);
}

.text-important {
    color: var(--color-white);
    font-weight: 600;
}

.text-quiet {
    color: var(--color-secondary-light-grey);
}

button {
    font-family: var(--default-font-family);
}

input,
select,
textarea {
    font-family: var(--default-font-family);
    &::placeholder {
        color: rgba(colors.$secondary-light-grey, 0.8);
        font-style: italic;
    }
}

hr {
    border: 0;
    border-top: shapes.$border;
}

/* prettier-ignore */
h1, h2, h3, h4, h5, h6, th {
    color: var(--body-heading-color);
}

aside {
    /* prettier-ignore */
    h1, h2, h3, h4 {
        color: var(--body-font-color);
    }
}

/* prettier-ignore */
h1, h2 {
  font-weight: var(--font-weight-semi-bold);
}

/* prettier-ignore */
h3, h4 {
  font-weight: var(--font-weight-medium);
}

h5, h6 {
  font-weight: var(--font-weight-regular);
}

dd {
    font-weight: var(--font-weight-light);
}

b, dt, strong, th {
    font-weight: var(--font-weight-semi-bold);
}

//h1 {
//    animation: 1000ms ease-in-out 0s fadeIn;
//}
//
//@keyframes fadeIn {
//    0% { opacity: 0; }
//    40% { opacity: 0; }
//    100% { opacity: 1; }
//}

//h1:hover {
//    text-shadow: 1px 1px 1px 1px #fff;
//}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

em em {
    font-style: normal;
}

strong strong {
    font-weight: normal;
}

summary {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

a {
    cursor: pointer;
    color: var(--color-white);
    text-decoration: none;

    p {
        color: var(--body-font-color);
    }
}

p, aside {
    a, a:visited {
        color: var(--color-green);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

address {
    font-style: normal;
}

code {
    hyphens: none;
    color: var(--code-font-color);
    background: var(--code-background);
    border-radius: var(--border-radius);
    border: shapes.$border;
    padding: 6px 6px 3px;
}

pre code {
    color: var(--pre-font-color);
    background: none;
    border-radius: 0;
    border: none;
    padding: 0;
}

form,
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

button, td-button {
    font-weight: var(--font-weight-regular);
}

::file-selector-button {
    border-radius: 4px;
    font-family: inherit;
    @include button-primary;
}

[hidden] {
    display: none !important;
}

.hide-mobile {
    @media (max-width: media.$max-width-mobile) {
        display: none !important;
    }
}

.hide-gt-mobile {
    @media (min-width: media.$min-width-tablet) {
        display: none !important;
    }
}

.hide-lt-desktop {
    @media (max-width: media.$max-width-tablet) {
        display: none !important;
    }
}

.hide-desktop {
    @media (min-width: media.$min-width-desktop) {
        display: none !important;
    }
}

.flex {
    flex: 1;
}

.card {
    @include card-appearance;
    @include card-padding;
    transition: border-color 100ms;

    &.hoverable:hover {
        border-color: var(--color-secondary-light-grey);
    }
}

.card-appearance {
    @include card-appearance;
}

.card-padding {
    @include card-padding;
}

.button-primary {
    @include button;
    @include button-primary;
}

.button-secondary {
    @include button;
    @include button-secondary;
}

td-form-actions, .td-form-actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    column-gap: 14px;
    row-gap: 8px;
    margin-top: 20px;

    button, td-button {
        flex: 1;
    }
}

.td-feedback-button {
    position: fixed;
    top: 50%;
    right: 0;
    transform: rotate(-90deg) translate(50%, -100%);
    transform-origin: right top;
    padding: 0 24px;
    z-index: 200;

    > * {
        display: block;
        border: none;
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-aside);
        transition: background-color 200ms ease-out, transform 200ms ease-out, box-shadow 200ms ease-out;
        transform: translateY(3px);
        outline: none;
        cursor: pointer;
        padding: 9px 26px;
        background-color: var(--color-purple);
        color: var(--body-font-color);
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        border: shapes.$border;
        box-shadow: 0 3px 26px 0 rgba(colors.$purple, 0.4);

        &:hover {
            transform: translateY(0);
            background-color: var(--color-light-purple);
            box-shadow: 0 3px 26px 0 rgba(colors.$secondary-light-grey, 0.2);
        }
    }

    @media (max-width: media.$max-width-mobile) {
        display: none;

        // top: 100%;
        // transform: rotate(-90deg) translate(100%, -100%);

        // > * {
        //     padding: 5px 15px;
        // }
    }
}

td-form .form-row {
    display: flex;
    gap: 16px;

    > * {
        flex: 1;
    }

    @media (max-width: media.$max-width-mobile) {
        flex-direction: column;
    }
}

td-feedback-dialog {
    .hs-form-field > label {
        margin-bottom: 8px;
        display: inline-block;
    }

    aside {
        margin-top: 32px;
    }
}
