/* Monaco */
@font-face {
    font-family: "Monaco";
    font-style: normal;
    font-weight: 400;
    src:
        url(../fonts/Monaco.woff2) format("woff2"),
        url(../fonts/Monaco.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 200;
    src: url(../fonts/DarkmodeOn_Trial_Th.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: italic;
    font-weight: 200;
    src: url(../fonts/DarkmodeOn_Trial_ThIt.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/DarkmodeOn_Trial_Lt.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/DarkmodeOn_Trial_Rg.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: italic;
    font-weight: 400;
    src: url(../fonts/DarkmodeOn_Trial_It.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/DarkmodeOn_Trial_Md.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/DarkmodeOn_Trial_SBd.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Darkmode";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/DarkmodeOn_Trial_Bd.ttf) format("truetype");
    font-display: swap;
}
