$white: #ffffff;
$dark-white: #f5f5f7;
$darker-white: #ccc;
$light-purple: #2d2a46;
$purple: #1a182a;
$deep-purple: #151322;
$black-purple: #0e0d17;
$green: #02dac9;
$red: #e96464;

$secondary-light-grey: #958fa8;
$secondary-grey: #63607c;
$secondary-mid-deep-grey: #4e4b63;
$secondary-deep-grey: #383649;
$secondary-purple: #232135;
$secondary-yellow: #ffe49e;
$secondary-pink: #ff87dc;
$secondary-blue: #7ba0ff;

$prism-white: #ece0ff;
$prism-cyan: #55eae2;
$prism-light-green: #02dac9;
$prism-green: #4dc97c;
$prism-red: #ff8080;
$prism-pink: #ff7abd;
$prism-blue: #82b6ff;
$prism-purple: #c099f3;
$prism-orange: #ffa187;
$prism-yellow-orange: #ffc980;
$prism-yellow: #ffe4a7;
$prism-grey: #c69ec3;
$prism-very-grey: #ac9ec6;
$prism-brick: #b88484;
