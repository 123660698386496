.td-scrollbar-container {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.td-scrollbar {
    position: absolute;

    &.td-scrollbar-x {
        left: 6px;
        bottom: 3px;
        right: 6px;
        height: 8px;

        .td-scrollbar-thumb {
            min-width: 16px;
        }
    }

    &.td-scrollbar-y {
        top: 6px;
        right: 3px;
        bottom: 6px;
        width: 8px;

        .td-scrollbar-thumb {
            min-height: 16px;
        }
    }
}

.td-scrollbar-thumb {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-light-purple);
    opacity: 0;
    border-radius: 4px;
    transition: opacity 200ms;

    .td-scrollbar-container:hover & {
        opacity: 0.5;
    }

    .td-scrollbar:hover &,
    &.td-active,
    .td-scrollbar-container:hover &.td-active {
        opacity: 1;
    }
}
