@use "colors";
@use "media";
@use "shapes" as *;
@use "typography";

:root {
    /* colors */
    --color-white: #{colors.$white};
    --color-dark-white: #{colors.$dark-white};
    --color-darker-white: #{colors.$darker-white};
    --color-light-purple: #{colors.$light-purple};
    --color-purple: #{colors.$purple};
    --color-deep-purple: #{colors.$deep-purple};
    --color-black-purple: #{colors.$black-purple};
    --color-green: #{colors.$green};
    --color-red: #{colors.$red};

    --color-secondary-light-grey: #{colors.$secondary-light-grey};
    --color-secondary-grey: #{colors.$secondary-grey};
    --color-secondary-deep-grey: #{colors.$secondary-deep-grey};
    --color-secondary-purple: #{colors.$secondary-purple};
    --color-secondary-yellow: #{colors.$secondary-yellow};
    --color-secondary-pink: #{colors.$secondary-pink};
    --color-secondary-blue: #{colors.$secondary-blue};

    --content-border-color: var(--color-light-purple);

    --body-background: var(--color-black-purple);
    --body-font-color: var(--color-dark-white);
    --body-heading-color: var(--color-white);
    --code-background: var(--color-deep-purple);
    --code-font-color: var(--color-secondary-pink);
    --pre-font-color: #{colors.$prism-white};
    --table-header-background: var(--color-deep-purple);
    --table-body-background: var(--color-purple);
    --table-footer-background: var(--color-purple);

    /* sizes */
    --border-radius: #{$border-radius};

    --section-page-margin: #{$section-page-margin};
    --section-width: #{$section-width};
    --section-max-width: calc(100vw - var(--section-page-margin) * 2);
    --blog-post-width: #{$blog-post-width};
    --blog-post-max-width: calc(var(--section-max-width) - #{$blog-post-gap} - #{$blog-post-sidebar-width});

    --first-section-margin: #{$first-section-margin};
    --section-margin: #{$section-margin};
    --subsection-margin: #{$subsection-margin};
    --subtitle-margin-l: #{$subtitle-margin-l};
    --subtitle-margin: #{$subtitle-margin};
    --blog-landing-strip-margin: #{$blog-landing-strip-margin};

    --text-margin-s: #{$text-margin-s};

    --font-weight-bold: #{typography.$bold};
    --font-weight-semi-bold: #{typography.$semi-bold};
    --font-weight-medium: #{typography.$medium};
    --font-weight-regular: #{typography.$regular};
    --font-weight-light: #{typography.$light};
    --font-weight-thin: #{typography.$thin};

    --topbar-height: #{$topbar-height};
    --banner-height: #{$banner-height};
    --scroll-padding: #{$scroll-padding};

    /* typography */

    --font-size-base: var(--font-size-p2);
    --body-letter-spacing: 0.02em;
    --default-font-family: #{typography.$font-family-default};
    --code-font-family: #{typography.$font-family-code};

    --font-size-h1: #{typography.$h1};
    --line-height-h1: #{typography.$height-h1};
    --font-size-h2: #{typography.$h2};
    --line-height-h2: #{typography.$height-h2};
    --font-size-h3: #{typography.$h3};
    --line-height-h3: #{typography.$height-h3};
    --font-size-h4: #{typography.$h4};
    --line-height-h4: #{typography.$height-h4};
    --font-size-h5: #{typography.$h5};
    --line-height-h5: #{typography.$height-h5};
    --font-size-p1: #{typography.$p1};
    --line-height-p1: #{typography.$height-p1};
    --font-size-p2: #{typography.$p2};
    --line-height-p2: #{typography.$height-p2};
    --font-size-blog: #{typography.$blog};
    --line-height-blog: #{typography.$height-blog};
    --font-size-aside: #{typography.$aside};
    --line-height-aside: #{typography.$height-aside};
    --font-size-code: #{typography.$code};
    --line-height-code: #{typography.$height-code};

    --card-padding: #{$card-padding};

    @media (max-width: media.$max-width-tablet) {
        --section-width: #{$section-width-tablet};
        --section-page-margin: #{$section-page-margin-tablet};

        --subsection-margin: #{$subsection-margin-tablet};

        --card-padding: #{$card-padding-tablet};
    }

    @media (max-width: media.$max-width-mobile) {
        --body-font-color: var(--color-white);

        --section-width: #{$section-width-mobile};
        --section-page-margin: #{$section-page-margin-mobile};
        --blog-post-max-width: var(--section-max-width);

        --first-section-margin: #{$first-section-margin-mobile};
        --section-margin: #{$section-margin-mobile};
        --subsection-margin: #{$subsection-margin-mobile};
        --subtitle-margin-l: #{$subtitle-margin-mobile};
        --subtitle-margin: #{$subtitle-margin-mobile};
        --blog-landing-strip-margin: #{$blog-landing-strip-margin-mobile};

        --topbar-height: #{$topbar-height-mobile};
        --banner-height: #{$banner-height-mobile};
        --scroll-padding: #{$scroll-padding-mobile};

        --font-size-h1: #{typography.$h1-mobile};
        --line-height-h1: #{typography.$height-h1-mobile};
        --font-size-h2: #{typography.$h2-mobile};
        --line-height-h2: #{typography.$height-h2-mobile};
        --font-size-h3: #{typography.$h3-mobile};
        --line-height-h3: #{typography.$height-h3-mobile};
        --font-size-h4: #{typography.$h4-mobile};
        --line-height-h4: #{typography.$height-h4-mobile};
        --font-size-h5: #{typography.$h5-mobile};
        --line-height-h5: #{typography.$height-h5-mobile};
        --font-size-p1: #{typography.$p1-mobile};
        --line-height-p1: #{typography.$height-p1-mobile};
        --font-size-p2: #{typography.$p2-mobile};
        --line-height-p2: #{typography.$height-p2-mobile};
        --font-size-blog: #{typography.$blog-mobile};
        --line-height-blog: #{typography.$height-blog-mobile};
        --font-size-aside: #{typography.$aside-mobile};
        --line-height-aside: #{typography.$height-aside-mobile};
        --font-size-code: #{typography.$code-mobile};
        --line-height-code: #{typography.$height-code-mobile};

        --card-padding: #{$card-padding-mobile};
    }
}
