@use "colors";

$border-radius: 8px;
$border: 1px solid colors.$light-purple;

$section-page-margin: 20px;
$section-width: 1200px - $section-page-margin * 2;
$section-page-margin-tablet: 32px;
$section-width-tablet: 1024px - $section-page-margin-tablet * 2;
$section-page-margin-mobile: 12px;
$section-width-mobile: calc(100vw - $section-page-margin-mobile * 2);
$blog-post-gap: 125px;
$blog-post-sidebar-width: 208px;
$blog-post-width: 700px;

$first-section-margin: 120px;
$section-margin: 144px;
$first-section-margin-mobile: 48px;
$section-margin-mobile: 64px;
$subsection-margin: 64px;
$subsection-margin-tablet: 56px;
$subsection-margin-mobile: 24px;
$blog-landing-strip-margin: 64px;
$blog-landing-strip-margin-mobile: 24px;

$subtitle-margin-l: 32px;
$subtitle-margin: 24px;
$subtitle-margin-mobile: 16px;

$text-margin-s: 16px;

$topbar-height: 72px;
$topbar-height-mobile: 72px;

$banner-height: 30px;
$banner-height-mobile: 26px;

$scroll-padding: 112px;
$scroll-padding-mobile: 64px;

$card-padding: 28px;
$card-padding-tablet: 24px;
$card-padding-mobile: 18px;

@mixin standard-border {
    border-radius: $border-radius;
    border: $border;
}
