@use "@angular/material" as mat;
@use "media";
@use "mixins" as *;
@use "shapes";

@import "base";
@import "fonts";
@import "material";
@import "prism";
@import "reset";
@import "scrollbars";
@import "vars";

$default-typography: mat.define-typography-level(
    $font-size: inherit,
);

$custom-typography: mat.define-typography-config(
    $font-family: var(--default-font-family),
    $headline-1: $default-typography,
    $headline-2: $default-typography,
    $headline-3: $default-typography,
    $headline-4: $default-typography,
    $headline-5: $default-typography,
    $headline-6: $default-typography,
    $subtitle-1: $default-typography,
    $subtitle-2: $default-typography,
    $body-1: $default-typography,
    $body-2: $default-typography,
    $caption: $default-typography,
    $button: $default-typography,
    $overline: $default-typography,
);

@include mat.core();
@include mat.dialog-typography($custom-typography);
@include mat.snack-bar-typography($custom-typography);

article {
    min-height: 1080px;

    > .article-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 144px;
        position: relative;

        &.article-content-no-planet {
            padding-bottom: 5em;
        }

        @media (max-width: media.$max-width-mobile) {
            padding-bottom: 64px;
        }
    }
}

section, .section {
    position: relative;
    z-index: 1;
    @include section-width($offset: 0px);
    margin-left: auto;
    margin-right: auto;
}

.narrow-section {
    max-width: 870px;
}

.nebula-section {
    margin-top: var(--first-section-margin);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    + article {
        margin-top: 40px;
        border-top: shapes.$border;

        section:first-of-type {
            margin-top: 64px;

            @media (max-width: media.$max-width-mobile) {
                margin-top: 24px;
            }
        }
    }
}

.blog-landing-strip {
    margin-top: var(--blog-landing-strip-margin);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.resource-section {
    margin-top: 40px;
    border-top: shapes.$border;
    background: var(--color-black-purple);

    @media (max-width: media.$max-width-mobile) {
        margin-top: 16px;
    }
}

.link-tertiary, .link-tertiary:visited {
    color: var(--color-dark-white);
    font-weight: var(--font-weight-regular);

    &::after {
        margin-left: 6px;
        content: "";
        font-family: "Font Awesome 6 Pro";
        font-size: 90%;
        user-select: none;
        display: inline-block;
    }

    &:hover, &:focus {
        color: var(--color-white);
        text-decoration: underline;
    }
}

input[type="email"], select {
    padding: 0 8px;
    background-color: var(--color-purple);
    color: var(--body-font-color);
    border: shapes.$border;
    caret-color: var(--body-font-color);
    border-radius: var(--border-radius);
    font-size: 16px;
    line-height: 46px;
    height: 48px;
}

table {
    table-layout: fixed;
    position: relative;
    border-collapse: collapse;
    background: none;

    thead + tbody {
        border-top: 1px solid var(--color-light-purple);
    }

    tr:not(:first-child) {
        border-top: 1px solid var(--color-purple);
    }

    th, td {
        color: var(--body-font-color);
        padding: 12px 24px;
        font-size: var(--font-size-p2);

        @media (max-width: media.$max-width-mobile) {
            padding: 8px 6px;
            font-size: var(--font-size-aside);
        }
    }

    th {
        font-weight: inherit;
    }
}

.mat-mdc-dialog-container table {
    tr:not(:first-child) {
        border-top-color: var(--color-light-purple);
    }
}

.page-intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: var(--first-section-margin);
}

.section-margin {
    margin-top: var(--section-margin);
}

.subsection-margin {
    margin-top: var(--subsection-margin);
}

.subtitle-l {
    margin-top: var(--subtitle-margin-l);
}

.subtitle {
    margin-top: var(--subtitle-margin);
}

.section-background-solid {
    background-color: var(--color-black-purple);
}

.cc-window {
    background: var(--color-purple);
    border-top: shapes.$border;
    font-family: inherit;
    transition-duration: 150ms;

    .cc-link {
        text-decoration: none;
        color: var(--color-green);
    }

    .cc-btn {
        @include button;
        @include button-primary;

        &.cc-btn {
            height: auto;
            width: auto;
        }
    }
}

.markdown pre {
    background: transparent;
}

.atcb-list {
    font-family: var(--default-font-family);
}

mat-dialog-container {
    h1 {
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
    }

    td-button {
        margin-top: 30px;
    }

    aside {
        margin-top: 32px;
    }

    .mat-mdc-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mdc-dialog__content {
        overflow: visible;
    }

    td-p-with-highlights {
        display: block;
        margin-bottom: 24px;
        color: var(--body-font-color);
    }
}

@property --gradient-button-bg-angle {
    inherits: false;
    initial-value: 120deg;
    syntax: "<angle>";
}

@keyframes gradient-button-bg-rotate {
    to {
        --gradient-button-bg-angle: 480deg;
    }
}
