@use "colors";
@use "media";
@use "shapes";

@mixin section-width($offset) {
    // max-width doesn't work with table-layout:fixed in Safari, so we use fine-grained media queries to set width.
    width: shapes.$section-width + $offset;
    max-width: var(--section-max-width);

    @media (min-width: media.$min-width-tablet) and (max-width: (shapes.$section-width-tablet + shapes.$section-page-margin-tablet * 2 - 1px)) {
        width: calc(100vw - #{shapes.$section-page-margin-tablet} * 2 + $offset);
    }

    @media (max-width: media.$max-width-mobile) {
        width: calc(100vw - 24px + $offset);
    }
}

@mixin line-clamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin button {
    display: flex;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius);
    justify-content: center;
    align-items: center;
    font-weight: var(--font-weight-regular);
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    transition-property: background-color, border, box-shadow, opacity;
    transition-duration: 100ms;
    transition-timing-function: ease;
    text-decoration: none !important; // overrides various styles that set an underline on anchors
    padding: 4px 8px;

    span {
        position: relative;
    }

    span::after {
        content: "";
        font-family: "Font Awesome 6 Pro";
        font-size: 75%;
        user-select: none;
        display: inline-block;
        position: absolute;
        inset: auto auto auto 100%;
        line-height: 18px;
        margin-left: 2px;
        opacity: 0;
        transform: scaleX(0.5);
        transition: opacity 100ms ease-out, margin-left 100ms ease-out, transform 100ms ease-out;
    }

    &:hover span::after {
        opacity: 1;
        margin-left: 8px;
        transform: scaleX(1);
    }

    @media (min-width: media.$min-width-tablet) {
        height: 48px;
        width: 212px;
    }

    @media (max-width: media.$max-width-mobile) {
        height: 40px;
    }

    &.td-button-disabled {
        opacity: 50%;
        cursor: default;
    }

    &.td-button-size-s {
        height: 40px;
    }

    &.td-button-size-xs {
        height: 36px;
    }
}

@mixin button-primary {
    border-color: var(--color-green);
    background: transparent;
    color: var(--color-green);

    &:not(.td-button-disabled) {
        &:hover,
        &.container-hover {
            border-color: #81EDE4;
            color: #81EDE4;
        }
    }
}

@mixin button-secondary {
    border-color: var(--color-darker-white);
    background: transparent;
    color: var(--body-font-color);

    &:not(.td-button-disabled) {
        &:hover, &.container-hover {
            color: var(--color-white);
        }
    }
}

@mixin card-appearance {
    border-radius: var(--border-radius);
    background-color: var(--color-purple);
    border: shapes.$border;
}

@mixin card-padding {
    padding: var(--card-padding);
}

@mixin content-table {
    background: var(--table-body-background);
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 1px var(--content-border-color);
    overflow: hidden;

    > * > tr {
        border-bottom: shapes.$border;

        > * {
            padding: 0.75rem 1.25rem;
            color: var(--color-white);
            font-size: 1rem;
            line-height: 1.75;
        }

        > th {
            font-weight: var(--font-weight-regular);
        }

        > td {
            font-weight: normal;
        }
    }

    > thead > tr > * {
        background: var(--table-header-background);

        &:first-child {
            border-top-left-radius: var(--border-radius);
        }

        &:last-child {
            border-top-right-radius: var(--border-radius);
        }
    }

    > colgroup col:not(:first-of-type) {
        border-left: shapes.$border;
    }

    > tfoot {
        background: var(--table-footer-background);
    }
}

@mixin url-icon($url, $color) {
    background: $color;
    mask-image: $url;
    -webkit-mask-image: $url;
    mask-size: 100%;
    -webkit-mask-size: 100%;
}

@mixin color-gradient($color1, $color2) {
    background: linear-gradient(105deg, #{$color1} 0%, #{$color2} 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin border-gradient($color1, $color2, $bg-color) {
    animation: gradient-button-bg-rotate 2.5s infinite linear paused;
    background:
        linear-gradient(to bottom, $bg-color, $bg-color) padding-box,
        conic-gradient(from var(--gradient-button-bg-angle), $color2, $color1, $color2) border-box;
    border: 1px solid transparent;
    color: var(--color-darker-white);

    &:hover {
        animation-play-state: running;
        color: var(--color-white);
    }

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        /* Variable angle in gradient doesn't work in Safari (Dec 2024) - results in empty background */
        background:
            linear-gradient(to bottom, $bg-color, $bg-color) padding-box,
            conic-gradient(from 120deg, $color2, $color1, $color2) border-box;
    }
}
